// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"src/modules/administrator/config.ts"
);
import.meta.hot.lastModified = "1715283272501.5898";
}
// REMIX HMR END

export const config = {
  path: '/dashboard/settings/administrators',
  apiPath: `/api/administrator`,
}
